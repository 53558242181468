<template>
  <div class="v-stack h-stretch gap-3">
    <div class="card light border h-stretch">
      <div class="content h-stretch">
        <form class="gap-3 h-stretch">
          <div class="pane-horizontal gap-3">
            <div class="v-stack h-stretch gap-3">
              <label class="text-left required">First Name</label>
              <input v-model="firstName" type="text" />
            </div>
            <div class="v-stack h-stretch gap-3">
              <label class="text-left required">Last Name</label>
              <input v-model="lastName" type="text" />
            </div>
          </div>
          <label class="text-left required">Email</label>
          <input v-model="email" type="email" />
          <label class="text-left">Phone Number</label>
          <input v-model="phoneNumber" type="text" />
          <div class="pane-horizontal gap-3">
            <label class="text-left">Profession</label>
            <label class="text-left">Daily Fee</label>
          </div>
          <div
            class="pane-horizontal gap-3"
            v-for="(proffession, index) in userCategories"
            :key="proffession.id"
          >
            <select v-model="proffession.name">
              <option v-for="category in categories" :key="category">
                {{ category }}
              </option>
            </select>
            <div class="pane-right gap-3">
              <input v-model="proffession.dailyFee" type="number" />
              <button class="edit" @click.prevent="removeProffession(index)">
                X
              </button>
            </div>
          </div>
          <button class="add" @click.prevent="addProffession()">Add</button>
          <label class="text-left">Rating</label>
          <select v-model="rating">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select>
          <label class="text-left">Daily Fee</label>
          <input v-model="dailyFee" type="number" />
          <label class="text-left">Description</label>
          <textarea v-model="description"></textarea>
          <div class="h-stack h-end">
            <button class="submit" @click.prevent="submit()">Add</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
const generator = require("generate-password");
import { v4 as uuidv4 } from "uuid";

export default {
  data() {
    return {
      categories: [],
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      category: "",
      rating: 0,
      description: "",
      dailyFee: 0,
      accessLevel: 0,
      userCategories: [],
    };
  },
  methods: {
    ...mapActions(["addUser", "getPricelistItems"]),
    addProffession() {
      this.userCategories.push({
        name: "",
        dailyFee: 0,
        id: uuidv4(),
      });
    },
    removeProffession(index) {
      this.userCategories.splice(index, 1);
    },
    submit() {
      const password = generator.generate({
        length: 8,
        numbers: true,
      });

      this.addUser({
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        password: password,
        accessLevel: this.accessLevel,
        category: this.category,
        rating: this.rating,
        description: this.description,
        dailyFee: this.dailyFee,
        phoneNumber: this.phoneNumber,
        categories: this.userCategories,
      })
        .then(() => {
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getPricelistItems()
      .then((items) => {
        const services = Array.from(
          new Set(
            items
              .filter((item) => item.categoryMain == "PERSONÁL / SLUŽBY")
              .map((item) => item.name)
          )
        );

        this.categories = services;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>